<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="provident_funds"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>

         <span v-else-if="props.column.field === 'employee.employee_id'">
          {{ getEmployeeId(intConvert(props.row.employee_id)) }}
        </span>

      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);
export default {
  name:'Department',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL#',
          field: 'id',
        },
        {
          label: 'Employee Id',
          field: 'employee.employee_id',
        },
        {
          label: 'Name',
          field: 'user.name',
        },
        {
          label: 'Month',
          field: 'month_year',
        },
        {
          label: 'Amount',
          field: 'provident_fund_amount',
        },
      ],
      searchTerm: '',
      columns10: [
        {
          label: 'Employee Id',
          field: 'EmployeeId',
        },
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Month',
          field: 'Month',
        },
        {
          label: 'Amount',
          field: 'Amount',
        },
      ],
    }
  },
  methods: {
    intConvert(dd){
      try {
        return parseInt(dd);
      }catch (e){
        return dd;
      }
    },
    pdfGenerate() {
      let title = "Provident Fund";
      let clm = ['EmployeeId','Name','Month','Amount' ];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'provident_fund_info.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['provident_funds', 'getEmployeeId']),
    getData() {
      return this.provident_funds.map(item => ({
        EmployeeId: item.employee ? item.employee.employee_id : '',
        Name: item.user ? item.user.name : '',
        Month: item.month_year ? item.month_year : '',
        Amount: item.provident_fund_amount ? item.provident_fund_amount : 0,
      }));
    }
  },
  created() {
    if(this.provident_funds.length<1) this.$store.dispatch('GET_ALL_PROVIDENT_FUND');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>